import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import VenueList from '../components/VenueList';

class ListPage extends PureComponent {
    render() {
        const {
            data
        } = this.props;

        return (
            <VenueList
                data={data}
            />
        );
    }
}

export const query = graphql`
    query {
        allContentfulVenue(sort: {fields: [venueName]}) {
            edges {
                node {
                    venueName
                    playlistToken
                    slug
                    disableListLink
                }
            }
        }
    }
`

ListPage.propTypes = {
    data: PropTypes.object.isRequired
};

export default ListPage;
