import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import OpenEarLogo from '../OpenEarLogo';
import './styles.scss';

class VenueList extends PureComponent {
    render() {
        const {
            data
        } = this.props;

        const pageLinks = data.allContentfulVenue.edges;

        return (
            <div className="venue-list">
                <div className="venue-list__logo">
                    <OpenEarLogo />
                </div>
                <ul className="venue-list__list">
                    {pageLinks
                        .sort(
                            (a, b) => a.node.venueName.toLowerCase().localeCompare(
                                b.node.venueName.toLowerCase()
                            )
                        )
                        .map((page) => {
                            if (!page.node.disableListLink) {
                                return (
                                    <li
                                        key={page.node.slug}
                                        className="venue-list__list-item"
                                    >
                                        <a
                                            className="venue-list__list-item-link"
                                            href={`/${page.node.slug}`}
                                        >
                                            {page.node.venueName}
                                        </a>
                                    </li>
                                );
                            }
                            return false
                        })}
                </ul>
            </div>
        );
    }
}

VenueList.propTypes = {
    data: PropTypes.object.isRequired
};

export default VenueList;
